exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pdamsterdam-2023-js": () => import("./../../../src/pages/pdamsterdam2023.js" /* webpackChunkName: "component---src-pages-pdamsterdam-2023-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */),
  "component---src-pages-submit-js": () => import("./../../../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-pages-volunteering-js": () => import("./../../../src/pages/volunteering.js" /* webpackChunkName: "component---src-pages-volunteering-js" */)
}

